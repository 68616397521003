import { useState, useCallback, useLayoutEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import PlayArrowRounded from '@material-ui/icons/PlayArrowRounded'
import StopRounded from '@material-ui/icons/StopRounded'
import { audioContext } from '@slc/utils'

const useStyles = makeStyles(theme => ({
  button: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    border: 'thin solid',
    borderColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export const AudioPlayer = ({ baseUrl, filename, ...delegated }) => {
  const classes = useStyles()
  const [playing, setPlaying] = useState(false)
  const audio = audioContext.useAudio()

  const onend = useCallback(() => {
    console.log('onEnd', filename)
    setPlaying(false)
  }, [setPlaying, filename])

  useLayoutEffect(() => {
    return () => {
      if (playing) {
        audio.stop(false)
      }
    }
  }, [playing, audio])

  return (
    <IconButton
      size='small'
      className={classes.button}
      onClick={() => {
        if (playing) {
          audio.stop()
        } else {
          audio.play(`${baseUrl}${filename}.mp3`, {
            onend,
            onstop: onend
          })
        }

        setPlaying(!playing)
      }}
      {...delegated}
    >
      {playing ? <StopRounded /> : <PlayArrowRounded />}
    </IconButton>
  )
}

export default AudioPlayer
