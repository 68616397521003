import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { cartPricing } from '@slc/pricing'
import { CUSTOM_MESSAGE } from '@slc/constants'

import find from 'lodash/find'
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'

const initialState = {
  messages: [],
  pricing: {},
  cart: {},
  options: {
    total: 0,
    options: []
  },
  total: {
    subtotal: 0,
    options: 0,
    taxes: 0,
    total: 0
  },
  format: '',
  support: {
    name: '',
    value: 0
  },
  promoCode: {
    pcid: '',
    percent: 0,
    value: 0
  },
  taxes: {
    name: '',
    percent: 0
  },
  acceptation: false,
  payment: '',
  order: null,
  name: ''
}

const resetCartStatus = state => {
  state.cart = initialState.cart
  state.total = initialState.total
  state.format = initialState.format
  state.acceptation = initialState.acceptation
  state.payment = initialState.payment
}

const updateOptionTotal = state => {
  const options = get(state, 'options.options', [])

  state.options.total = options.reduce((acc, { value }) => acc + value, 0)

  updateTotal(state)
}

const updateCart = state => {
  if (isEmpty(state.messages)) {
    resetCartStatus(state)
    return
  }

  if (!isEmpty(state.pricing)) {
    state.cart = cartPricing(state.messages, state.pricing, true)

    updateTotal(state)
  }
}

const applyPromoCode = (subtotal, promoCode = {}) => {
  if (has(promoCode, 'value')) {
    const value = get(promoCode, 'value', 0)

    return subtotal - value
  }

  if (has(promoCode, 'percent')) {
    const value = get(promoCode, 'percent', 0)

    return Math.abs(subtotal * (100 - value))
  }

  return subtotal
}

const updateTotal = state => {
  if (state.cart.total > 0) {
    const support = get(state.support, 'value', 0)
    const taxPercent = get(state.taxes, 'percent', 0)
    const totalOptions = get(state.options, 'total', 0)

    const subtotal = applyPromoCode(
      state.cart.total + support + totalOptions,
      state.promoCode
    )
    const taxes = taxPercent > 0 ? Math.floor(subtotal * taxPercent) / 100 : 0

    state.total = {
      subtotal,
      taxes,
      total: subtotal + taxes
    }
  }
}

const extractMessage = ({ category, selection }) => {
  if (category !== CUSTOM_MESSAGE) {
    return selection[category]
  }

  const { blocks, translation, origin } = selection[CUSTOM_MESSAGE]

  return !isEmpty(origin.lang) &&
    !isEmpty(origin.from) &&
    !isEmpty(origin.text) &&
    translation.includes(origin.lang)
    ? selection[CUSTOM_MESSAGE]
    : { blocks, translation }
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setPricing(state, action) {
      const { payload } = action

      state.pricing = payload

      updateCart(state)
    },

    addMessage(state, action) {
      const { music, langs, voices, message } = action.payload
      const { type, category, instructions, options } = message

      state.messages.push({
        mid: uuid(),
        music,
        voices,
        langs,
        message: {
          type,
          category,
          instructions,
          options,
          selection: extractMessage(message)
        }
      })

      updateCart(state)
    },

    removeMessage(state, action) {
      const { mid } = action.payload
      const index = state.messages.findIndex(message => message.mid === mid)

      if (index === -1) {
        return
      }

      state.messages.splice(index, 1)

      updateCart(state)
    },

    removePart(state, action) {
      const { mid, pid } = action.payload

      const message = find(state.cart.messages, { mid })
      const { lid } = find(message.parts, { pid })

      const index = state.messages.findIndex(message => message.mid === mid)
      const messageInfos = state.messages[index]

      messageInfos.voices = messageInfos.voices.filter(
        voice => voice.lid !== lid
      )
      messageInfos.langs = messageInfos.langs.filter(lang => lang.lid !== lid)

      if (messageInfos.message.category === CUSTOM_MESSAGE) {
        const { selection } = messageInfos.message

        messageInfos.message.selection.blocks = selection.blocks.filter(
          ({ lang }) => lang.lid !== lid
        )

        messageInfos.message.selection.translation = selection.translation.filter(
          $lid => $lid !== lid
        )
      }

      state.messages[index] = messageInfos

      updateCart(state)
    },

    setSupport(state, action) {
      const { support } = action.payload

      state.support = support

      updateTotal(state)
    },

    setFormat(state, action) {
      const { format } = action.payload

      state.format = format
    },

    toggleOption(state, action) {
      const { name, value } = action.payload

      const options = get(state, 'options.options', [])

      const index = options.findIndex(option => option.name === name)

      index > -1 ? options.splice(index, 1) : options.push({ name, value })

      updateOptionTotal(state)
    },

    setTaxes(state, action) {
      const { name, percent } = action.payload

      state.taxes = { name, percent }

      updateTotal(state)
    },

    setPromoCode(state, action) {
      const { promoCode } = action.payload

      state.promoCode = promoCode

      updateTotal(state)
    },

    setAcceptation(state, action) {
      const { acceptation } = action.payload

      state.acceptation = acceptation
    },

    setPayment(state, action) {
      const { payment } = action.payload

      state.payment = payment
    },

    setOrder(state, action) {
      const { order } = action.payload

      state.order = order
    },

    setName(state, action) {
      const { name } = action.payload

      state.name = name
    },

    refreshCart(state, action) {
      updateCart(state)
    },

    resetCart(state, action) {
      state.messages = initialState.messages

      updateCart(state)
    }
  }
})

const { actions, reducer } = cartSlice

export const {
  setPricing,
  addMessage,
  removeMessage,
  removePart,
  setSupport,
  setFormat,
  toggleOption,
  setTaxes,
  setPromoCode,
  setAcceptation,
  setPayment,
  setOrder,
  setName,
  refreshCart,
  resetCart
} = actions

export default reducer
