import { defineMessage } from '@lingui/macro'

export const links = {
  $name: 'customer',
  // customer
  dashboard: {
    href: '',
    title: defineMessage({
      id: 'links.customer.dashboard'
    })
  },
  orders: {
    href: '/order',
    title: defineMessage({
      id: 'links.customer.orders'
    })
  },
  order: {
    href: '/order/:oid',
    title: defineMessage({
      id: 'links.customer.order'
    })
  },
  messageOrders: {
    href: '/message',
    title: defineMessage({
      id: 'links.customer.messages'
    })
  },
  messageOrder: {
    href: '/message/:oid',
    title: defineMessage({
      id: 'links.customer.message'
    })
  },
  payment: {
    href: '/message/:oid/payment',
    title: defineMessage({
      id: 'links.customer.payment'
    })
  },
  certificate: {
    href: '/message/:oid/certificate',
    title: defineMessage({
      id: 'links.customer.certificate'
    })
  },
  downloadCertificate: {
    href: '/message/:oid/certificate/download',
    title: defineMessage({
      id: 'links.customer.downloadCertificate'
    })
  },
  invoice: {
    href: '/message/:oid/invoice',
    title: defineMessage({
      id: 'links.customer.invoice'
    })
  },
  downloadInvoice: {
    href: '/message/:oid/invoice/download',
    title: defineMessage({
      id: 'links.customer.downloadInvoice'
    })
  },
  studio: {
    href: '/message/:oid/studio',
    title: defineMessage({
      id: 'links.customer.studio'
    })
  },
  quotes: {
    href: '/quote',
    title: defineMessage({
      id: 'links.customer.quotes'
    })
  },
  quote: {
    href: '/quote/:oid',
    title: defineMessage({
      id: 'links.customer.quote'
    })
  },
  invoices: {
    href: '/invoices',
    title: defineMessage({
      id: 'links.customer.invoices'
    })
  },
  infos: {
    href: '/infos',
    title: defineMessage({
      id: 'links.customer.infos'
    })
  },
  credits: {
    href: '/credit',
    title: defineMessage({
      id: 'links.customer.credit'
    })
  },
  topup: {
    href: '/credit/topup',
    title: defineMessage({
      id: 'links.customer.credit.topup'
    })
  },
  statements: {
    href: '/credit/statements',
    title: defineMessage({
      id: 'links.customer.credit.statements'
    })
  },
  support: {
    href: '/support',
    title: defineMessage({
      id: 'links.customer.support'
    })
  }
}