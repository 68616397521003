import { defineMessage } from '@lingui/macro'

export const links = {
  $name: 'auth',
  // signin: {
  //   href: '/auth/signin',
  //   title: defineMessage({
  //     id: 'links.signin',
  //     message: 'Connexion'
  //   })
  // },
  // signup
  signup: {
    href: '/auth/signup',
    title: defineMessage({
      id: 'links.auth.signup'
    })
  },
  signupRegister: {
    href: '/auth/signup/register',
    title: defineMessage({
      id: 'links.auth.signupRegister'
    })
  },
  signupAccount: {
    href: '/auth/signup/account',
    title: defineMessage({
      id: 'links.auth.signupAccount'
    })
  },
  signupDetails: {
    href: '/auth/signup/details',
    title: defineMessage({
      id: 'links.auth.signupDetails'
    })
  },
  signout: {
    href: '/auth/signout',
    title: defineMessage({
      id: 'links.auth.signout'
    })
  }
}