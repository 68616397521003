import { useMemo } from 'react'

import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import storageSession from 'redux-persist/lib/storage/session'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import logger from 'redux-logger'

import pkg from '../package.json'
import rootReducer from './reducers'

let store

const persistedReducer = persistReducer({
  key: pkg.name,
  version: pkg.version,
  storage,
  stateReconciler: autoMergeLevel2,
  // whitelist: ['cart', 'wizard'],
  debug: process.env.NODE_ENV === 'development',
  timeout: null // https://github.com/rt2zz/redux-persist/issues/786#issuecomment-421850652
}, rootReducer)

const initStore = (preloadedState = {}) =>
  configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (global.window ? [logger] : []).concat(
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      })
    ),
    devTools: process.env.NODE_ENV !== 'production'
  })

export const initializeStore = preloadedState => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return _store
  }

  // Create the store once in the client
  if (!store) {
    store = _store
  }

  return _store
}

export function useStore (initialState = {}) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  return store
}

export * from './reducers'
