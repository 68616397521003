import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  }
}))

export const H2 = ({ children, variant = 'h2' }) => {
  const classes = useStyles()

  return (
    <Typography component="h2" variant={variant} className={classes.root}>
      {children}
    </Typography>
  )
}

