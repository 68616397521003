import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';

const CtaButton = withStyles(theme => ({
  root: {
    background: 'linear-gradient(90deg, #feb333 30%, #FF8E53 90%)',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    border: 0,
    borderRadius: 30,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px'
  }
}))(Button)

export default CtaButton
