import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  step: 0,
  account: {
    email: null
  },
  infos: {},
  details: {}
}

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setStep (state, action) {
      const { step } = action.payload

      state.step = step
    },
    register (state) {

    },
    setInfos (state) {

    },
    setDetails (state) {

    }
  }
})

const { actions, reducer } = signupSlice

export const {
  register,
  setInfos,
  setDetails
} = actions

export default reducer
