export const MUSIC_STEP = 0
export const VOICE_STEP = 1
export const MESSAGE_STEP = 2
export const CART_STEP = 3
export const CHECKOUT_STEP = 4

export const WIZARD_STEPS = [
  MUSIC_STEP,
  VOICE_STEP,
  MESSAGE_STEP,
  CART_STEP,
  CHECKOUT_STEP
]

export const CUSTOM_MUSIC_OPTION = 'customMusic'
export const GSM_DOWNLOAD_OPTION = 'gsmDownload'
export const MIXING_OPTION = 'mixing'
export const COMMERCIAL_RIGHTS_OPTION = 'commercialRights'
export const DIFFUSION_RIGHTS_OPTION = 'diffusionRights'
export const PROOF_READING_OPTION = 'proofreading'
export const RETAKE_OPTION = 'retake'
export const EXPRESS_DELIVERY_OPTION = 'expressDelivery'
export const THREE_YEAR_ARCHIVE_OPTION = 'threeYearArchive'

export const WIZARD_OPTIONS = [
  CUSTOM_MUSIC_OPTION,
  GSM_DOWNLOAD_OPTION,
  THREE_YEAR_ARCHIVE_OPTION,
  EXPRESS_DELIVERY_OPTION,
  RETAKE_OPTION,
  PROOF_READING_OPTION,
  DIFFUSION_RIGHTS_OPTION,
  COMMERCIAL_RIGHTS_OPTION,
  MIXING_OPTION
]
