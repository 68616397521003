import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

import { links as app } from './app'
import { links as auth } from './auth'
import { links as customer } from './customer'
import { links as dashboard } from './dashboard'

export const links = { app, auth, customer, dashboard }

const paramPattern = /:([^/]+)/g

const $getLink = (name, collection = null, baseUrl = null) => {
  if (!links) {
    throw new Error('Missing links collection')
  }

  const link = get(collection, name)

  if (!link) {
    throw new Error(`Invalid link #[${name}] for collection ${collection.$name}.`)
  }

  if (!baseUrl) {
    return link
  }
}

const $getLinkHref = (name, params, collection, baseUrl = '') => {
  if (!baseUrl) {
    throw new Error('Missing base url')
  }
  
  let link =  get(isString(name) ? $getLink(name, collection) : name, 'href')
  let href = `${baseUrl}${link}`

  if (isEmpty(params)) {
    return href
  }

  const tokens = href.match(paramPattern)

  if (!tokens) {
    return href
  }

  tokens.forEach(token => {
    const value = params[token.substring(1)]

    if (value) {
      href = href.replace(token, value)
    }
  })

  return href
}


export const getAuthLink = name => $getLink(name, auth, process.env.NEXT_PUBLIC_AUTH_URL)

export const getAuthLinkHref = (name, params) => 
  $getLinkHref(name, params, auth, process.env.NEXT_PUBLIC_AUTH_URL)

export const getAppLink = name => $getLink(name, app, process.env.NEXT_PUBLIC_APP_URL)

export const getAppLinkHref = (name, params) => 
  $getLinkHref(name, params, app, process.env.NEXT_PUBLIC_APP_URL)

export const getCustomerLink = name => $getLink(name, customer, process.env.NEXT_PUBLIC_CUSTOMER_URL)

export const getCustomerLinkHref = (name, params) => 
  $getLinkHref(name, params, customer, process.env.NEXT_PUBLIC_CUSTOMER_URL)

export const getDashboardLink = name => $getLink(name, dashboard, process.env.NEXT_PUBLIC_DASHBOARD_URL)

export const getDashboardLinkHref = (name, params) => 
  $getLinkHref(name, params, dashboard, process.env.NEXT_PUBLIC_DASHBOARD_URL)
