
export const CREATED_ORDER = 'created'
export const ACCEPTED_ORDER = 'accepted'
export const CANCELLED_ORDER = 'cancelled'
export const WAITING_PAYMENT_ORDER = 'waiting_payment'
export const PAID_ORDER = 'paid'
export const PRODUCTION_ORDER = 'production'
export const COMPLETE_ORDER = 'complete'

export const ORDER_STATUSES = [
  CREATED_ORDER,
  ACCEPTED_ORDER,
  CANCELLED_ORDER,
  WAITING_PAYMENT_ORDER,
  PAID_ORDER,
  PRODUCTION_ORDER,
  COMPLETE_ORDER
]
