import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import {
  // message
  STANDARD_MESSAGE,
  SEMI_STANDARD_MESSAGE,
  CUSTOM_MESSAGE,
  // step
  MUSIC_STEP,
  VOICE_STEP,
  MESSAGE_STEP,
  CART_STEP,
  // option
  CUSTOM_MUSIC_OPTION
} from '@slc/constants'

const initialState = {
  step: 0,
  music: {
    category: {
      cid: null,
      name: ''
    },
    music: {
      mid: null,
      name: ''
    },
    custom: false
  },
  langs: [],
  voices: [],
  message: {
    type: {
      mtid: null,
      type: ''
    },
    category: '',
    instructions: {},
    options: {},
    selection: {
      [STANDARD_MESSAGE]: {
        mid: null,
        text: ''
      },
      [SEMI_STANDARD_MESSAGE]: {
        mid: null,
        firm: '',
        text: ''
      },
      [CUSTOM_MESSAGE]: {
        blocks: [],
        translation: [],
        origin: {}
      }
    }
  }
}

const addLangCustomBlock = (lang, index = 0) => {
  return {
    bid: uuid(),
    lang,
    text: '',
    index
  }
}

const addTranslationOriginInfos = (state, payload) => {
  const origin = state.message.selection[CUSTOM_MESSAGE].origin || {}

  state.message.selection[CUSTOM_MESSAGE].origin = { ...origin, ...payload }
}

const messageBlocks = (langs, blocks = [], reset = false) => {
  if (!blocks.length) {
    return langs.map(lang => addLangCustomBlock(lang))
  }

  return reset ? [] : blocks
}

const configureCustomMessage = (state, reset = false) => {
  state.message.selection[CUSTOM_MESSAGE] = {
    ...initialState.message.selection[CUSTOM_MESSAGE],
    blocks: messageBlocks(
      state.langs,
      state.message.selection[CUSTOM_MESSAGE].blocks,
      reset
    ),
    translation: [],
    origin: {}
  }
}

const resetMessageOptions = message => {
  const { options = {} } = message

  return !options[CUSTOM_MUSIC_OPTION]
    ? {}
    : {
        [CUSTOM_MUSIC_OPTION]: { enabled: true }
      }
}

const resetMessageOnCartAddition = state => {
  const { category, selection } = state.message

  const message = {
    ...initialState.message
  }

  message.options = resetMessageOptions(message)

  const { firm } = selection[SEMI_STANDARD_MESSAGE]

  if (category === SEMI_STANDARD_MESSAGE && firm.length > 0) {
    message.selection = {
      ...initialState.message.selection,
      [SEMI_STANDARD_MESSAGE]: {
        ...initialState.message.selection[SEMI_STANDARD_MESSAGE],
        firm
      }
    }
  }

  return message
}

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    setStep(state, action) {
      const { step } = action.payload

      state.step = step
    },
    selectMusic(state, action) {
      const { category, music } = action.payload

      state.step = MUSIC_STEP
      state.music = {
        category,
        music,
        custom: false
      }
    },

    addLang(state, action) {
      const lang = action.payload

      const langIndex = state.langs.findIndex(({ lid }) => lang.lid === lid)

      if (langIndex !== -1) {
        return
      }

      state.langs.push(lang)

      state.step = VOICE_STEP
    },

    deleteLang(state, action) {
      const { lid } = action.payload

      const index = state.langs.findIndex(lang => lang.lid === lid)

      if (index > -1) {
        state.langs.splice(index, 1)
        state.voices.splice(index, 1) // use same lang index
      }
    },

    addVoice(state, action) {
      const { voice } = action.payload
      const langIndex = state.langs.findIndex(lang => lang.lid === voice.lid)

      if (langIndex === -1) {
        state.voices.push(voice)
      } else {
        state.voices[langIndex] = voice
      }

      state.step = VOICE_STEP
    },

    deleteLangVoice(state, action) {
      const { lang } = action.payload

      const vIndex = state.voices.findIndex(voice => voice.lid === lang.lid)

      if (vIndex > -1) {
        state.voices.splice(vIndex, 1)
      }
    },

    deleteVoice(state, action) {
      const { voice } = action.payload
      const index = state.voices.findIndex(({ vid }) => vid === voice.vid)

      if (index > -1) {
        state.voices.splice(index, 1)
      }
    },
    selectMessageType(state, action) {
      const { mtid, type } = action.payload

      state.step = MESSAGE_STEP
      state.message.type = { mtid, type }
      state.message.options = resetMessageOptions(state.message)

      if (state.message.category === CUSTOM_MESSAGE) {
        configureCustomMessage(state)
      }
    },
    selectMessageCategory(state, action) {
      const { category } = action.payload

      state.step = MESSAGE_STEP

      if (category === CUSTOM_MESSAGE) {
        console.warn('use selectCustomMessage')
        return
      }

      state.message.category = category
    },
    selectStandardMessage(state, action) {
      const { mid, text } = action.payload

      state.message.category = STANDARD_MESSAGE
      state.message.selection[STANDARD_MESSAGE] = {
        mid,
        text
      }
    },
    selectSemiStandardMessage(state, action) {
      const { mid, text } = action.payload

      state.message.category = SEMI_STANDARD_MESSAGE
      state.message.selection[SEMI_STANDARD_MESSAGE].mid = mid
      state.message.selection[SEMI_STANDARD_MESSAGE].text = text
    },
    selectSemiStandardMessageFirm(state, action) {
      const { firm } = action.payload

      state.message.selection[SEMI_STANDARD_MESSAGE].firm = firm
    },
    selectCustomMessage(state) {
      configureCustomMessage(state, state.message.category !== CUSTOM_MESSAGE)

      // last set category
      state.message.category = CUSTOM_MESSAGE
    },
    setCustomBlockText(state, action) {
      const { bid, text } = action.payload

      const { blocks } = state.message.selection[CUSTOM_MESSAGE]
      const blockIndex = blocks.findIndex(block => block.bid === bid)

      if (blockIndex < 0) {
        return
      }

      state.message.selection[CUSTOM_MESSAGE].blocks[blockIndex] = {
        ...state.message.selection[CUSTOM_MESSAGE].blocks[blockIndex],
        text
      }
    },
    addCustomBlock(state, action) {
      const { lang, index } = action.payload

      const { blocks } = state.message.selection[CUSTOM_MESSAGE]

      blocks.push(addLangCustomBlock(lang, index))
    },
    removeCustomBlock(state, action) {
      const { bid } = action.payload

      const index = state.message.selection[CUSTOM_MESSAGE].blocks.findIndex(
        block => block.bid === bid
      )

      if (index > -1) {
        state.message.selection[CUSTOM_MESSAGE].blocks.splice(index, 1)
      }
    },
    toggleCustomTranslation(state, action) {
      const { lid } = action.payload

      const { translation } = state.message.selection[CUSTOM_MESSAGE]
      const tIndex = translation.findIndex($lid => $lid === lid)

      if (tIndex > -1) {
        state.message.selection[CUSTOM_MESSAGE].translation.splice(tIndex, 1)
        state.message.selection[CUSTOM_MESSAGE].origin = {}
      } else {
        state.message.selection[CUSTOM_MESSAGE].translation.push(lid)

        const blocks = state.message.selection[CUSTOM_MESSAGE].blocks.filter(
          block => block.lang.lid === lid
        )

        blocks[0].text = ''

        if (blocks.length > 1) {
          blocks.forEach(({ bid }, index) => {
            if (index === 0) {
              return
            }

            const lIndex = state.message.selection[
              CUSTOM_MESSAGE
            ].blocks.findIndex(block => block.bid === bid)

            if (lIndex > -1) {
              state.message.selection[CUSTOM_MESSAGE].blocks.splice(lIndex, 1)
            }
          })
        }
      }
    },

    setTranslationOrigin(state, action) {
      const { lang, from } = action.payload

      addTranslationOriginInfos(state, { lang, from })
    },
    setTranslationOriginText(state, action) {
      const { text } = action.payload

      addTranslationOriginInfos(state, { text })
    },
    addInstructions(state, action) {
      const { instructions } = action.payload

      state.message.instructions = instructions
    },
    toggleOption(state, action) {
      const { name, enabled } = action.payload

      const option = state.message.options[name]

      if (enabled === true || enabled === false) {
        state.message.options[name] = { ...option, enabled }
        return
      }

      const hasOption = state.message.options.hasOwnProperty(name)

      if (!hasOption) {
        state.message.options[name] = { enabled }
      } else {
        state.message.options[name].enabled = enabled
      }
    },
    toggleCustomMusicOption(state, action) {
      const { name, enabled } = action.payload

      state.music.custom = enabled

      if (enabled) {
        state.music.category = initialState.music.category
        state.music.music = initialState.music.music
      }

      const hasOption = state.message.options.hasOwnProperty(name)

      if (!hasOption) {
        state.message.options[name] = { enabled }
      } else {
        state.message.options[name].enabled = enabled
      }
    },

    addOptionInfo(state, action) {
      const { name, ...infos } = action.payload

      const hasOption = state.message.options.hasOwnProperty(name)

      if (!hasOption) {
        state.message.options[name] = {
          ...infos
        }
      } else {
        const option = state.message.options[name]

        state.message.options[name] = {
          ...option,
          ...infos
        }
      }
    },

    reset(state) {
      state.step = initialState.step
      state.music = initialState.music
      state.langs = initialState.langs
      state.voices = initialState.voices
      state.message = initialState.message
    }
  },
  extraReducers: {
    // reset message selection
    'cart/addMessage': state => {
      state.message = resetMessageOnCartAddition(state)

      state.step = CART_STEP
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = wizardSlice

// Extract and export each action creator by name
export const {
  setStep,
  selectMusic,
  toggleCustomMusic,
  addLang,
  deleteLang,
  addVoice,
  deleteLangVoice,
  deleteVoice,
  selectMessageType,
  selectMessageCategory,
  selectStandardMessage,
  selectSemiStandardMessage,
  selectSemiStandardMessageFirm,
  selectCustomMessage,
  setCustomBlockText,
  addCustomBlock,
  removeCustomBlock,
  toggleCustomTranslation,
  setTranslationOrigin,
  setTranslationOriginText,
  addInstructions,
  toggleOption,
  toggleCustomMusicOption,
  addOptionInfo,
  reset
} = actions

// Export the reducer, either as a default or named export
export default reducer
