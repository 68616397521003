import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  }
}))

export const H1 = ({ children }) => {
  const classes = useStyles()

  return (
    <Typography component="h1" variant="h1" className={classes.root}>
      {children}
    </Typography>
  )
}

