import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react'
import { useRouter } from 'next/router'

export const wizardContextBuilder = ({ steps }) => {

  const initialState = {
    activeStep: 0
  }
  
  const WizardContext = createContext(initialState)
  
  const useWizard = () => useContext(WizardContext)
  
  const WizardProvider = ({ activeStep, children }) => {
    const router = useRouter()
    const [step, setActiveStep] = useState(activeStep || 0)
    const isFirstStep = step === 0
    const isLastStep = step === steps.length - 1
  
    const handleBack = useCallback(() => {
      if (!isFirstStep) {
        setActiveStep(step - 1)
      }
    }, [isFirstStep, step])
  
    const handleNext = useCallback(() => {
      if (!isLastStep) {
        setActiveStep(step + 1)
      }
    }, [isLastStep, step])
  
    const value = useMemo(
      () => ({
        ...initialState,
        activeStep: step,
        setActiveStep,
        isFirstStep,
        isLastStep,
        handleBack,
        handleNext
      }),
      [step, isFirstStep, isLastStep, handleBack, handleNext]
    )
  
    useEffect(() => {
      // TODO: fix step definition
      const { href } = steps[step].link
  
      if (href === router.route) {
        return
      }
  
      router.push(href, undefined, { shallow: true, scroll: true })
    }, [step, router])
  
    useEffect(() => {
      const { link } = steps[step === steps.length - 1 ? step - 1 : step + 1]
  
      router.prefetch(getLinkHref(link))
    }, [step, router])
  
    return (
      <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
    )
  }

  return {
    useWizard,
    WizardProvider
  }
}