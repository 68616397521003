import React from 'react'
import { App } from '@slc/appshell'
import { useStore } from '@slc/store'

function MyApp(props) {
  const { Component, pageProps } = props

  return (
    <App 
      useStore={useStore}
      Component={Component} 
      pageProps={pageProps} 
    />
  )
}

export default MyApp