import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  infos: {
    cid: null,
    firstname: null,
    lastname: null,
    billing: {
      address: '',
      address2: '',
      zip: '',
      city: ''
    },
    type: null
  },
  customer: null,
  balance: null
}

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setInfos(state, action) {
      state.infos = action.payload
    },

    setCustomer(state, action) {
      state.customer = action.payload.customer
    },

    setBalance(state, action) {
      state.balance = action.payload.balance
    }
  }
})

const { actions, reducer } = customerSlice

export const { setInfos, setCustomer, setBalance } = actions

export default reducer
