import { defineMessage } from '@lingui/macro'

export const links = {
  $name: 'dashboard',
  // dashboard
  dashboard: {
    href: '',
    title: defineMessage({
      id: 'links.dashboard.dashboard'
    })
  }
}