import { defineMessage } from '@lingui/macro'

export const links = {
  $name: 'app',
  home: {
    href: '/',
    title: defineMessage({
      id: 'links.app.home'
    })
  },
  customer: {
    href: '',
    title: defineMessage({
      id: 'links.app.customer'
    })
  },
  // shop
  order: {
    href: '/order',
    title: defineMessage({
      id: 'links.app.order'
    })
  },
  orderMusic: {
    href: '/order/music',
    title: defineMessage({
      id: 'links.app.orderMusic'
    })
  },
  orderVoice: {
    href: '/order/voice',
    title: defineMessage({
      id: 'links.app.orderVoice'
    })
  },
  orderMessage: {
    href: '/order/message',
    title: defineMessage({
      id: 'links.app.orderMessage'
    })
  },
  cart: {
    href: '/order/cart',
    title: defineMessage({
      id: 'links.app.cart'
    })
  },
  checkout: {
    href: '/order/checkout',
    title: defineMessage({
      id: 'links.app.checkout'
    })
  },
  payment: {
    href: '/order/payment',
    title: defineMessage({
      id: 'links.app.payment'
    })
  },
  orderComplete: {
    href: '/order/thank-you',
    title: defineMessage({
      id: 'links.app.orderComplete'
    })
  },
  // static
  about: {
    href: '/about',
    title: defineMessage({
      id: 'links.app.about'
    })
  },
  contact: {
    href: '/contact',
    title: defineMessage({
      id: 'links.app.contact'
    })
  },
  pricing: {
    href: '/pricing',
    title: defineMessage({
      id: 'links.app.pricing'
    })
  },
  // auths
  auth: {
    href: '/auth',
    title: defineMessage({
      id: 'links.app.auth'
    })
  },
  signout: {
    href: '/signout',
    title: defineMessage({
      id: 'links.app.signout'
    })
  },
  // legals
  terms: {
    href: '/legals/terms-of-use',
    title: defineMessage({
      id: 'links.app.terms'
    })
  },
  cookies: {
    href: '/legals/cookie-policy',
    title: defineMessage({
      id: 'links.app.cookies'
    })
  },
  privacy: {
    href: '/legals/privacy-policy',
    title: defineMessage({
      id: 'links.app.privacy'
    })
  },
  notice: {
    href: '/legals/notice',
    title: defineMessage({
      id: 'links.app.notice'
    })
  },
  support: {
    href: '/support',
    title: defineMessage({
      id: 'links.app.support'
    })
  },
  // reseller
  affiliate: {
    href: '/affiliates',
    title: defineMessage({
      id: 'links.app.affiliate'
    })
  }
}