import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cid: null,
  site: null,
  currency: {
    name: null,
    symbol: null,
    code: null
  },
  tax: {
    name: null,
    percent: null
  },
  langs: []
}

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setInfos(state, action) {
      const { cid, site, currency, tax, langs } = action.payload
      state.cid = cid
      state.site = site
      state.currency = currency
      state.tax = tax
      state.langs = langs
    }
  }
})

const { actions, reducer } = countrySlice

export const { setInfos } = actions

export default reducer
