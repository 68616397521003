import { combineReducers } from 'redux'

import cartReducer, * as cart from './cart'
import wizardReducer, * as wizard from './wizard'
import signupReducer, * as signup from './signup'
import countryReducer, * as country from './country'
import customerReducer, * as customer from './customer'

const rootReducer = (reducers = {}) => combineReducers({
  cart: cartReducer,
  wizard: wizardReducer,
  signup: signupReducer,
  country: countryReducer,
  customer: customerReducer,
  ...reducers
})

export { 
  rootReducer as default,
  cart, 
  wizard, 
  signup, 
  country, 
  customer 
}
