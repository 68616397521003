import React from 'react';
import MuiButton from '@material-ui/core/Button'

export const Button = ({ children, ...others }) => (
  <MuiButton disableElevation variant="contained" color="primary" {...others}>
    {children}
  </MuiButton>
)

export default Button
